import excavator from '../img/excavator.svg'
import man from '../img/man.svg'
import time from '../img/time.svg'
import key from '../img/key.svg'

export const PreeminenceData = [
  {
    title: 'Оборудование',
    content: `Для выполнения проектов используется новейшее оборудование, что исключает простои, основные технические средства имеют срок эксплуатации менее 3-х лет, регулярно обслуживаются и находятся в отличном состоянии.`,
    picture: excavator
},
  {
    title: 'Механическая служба',
    content: `Благодаря высококвалифицированным специалистам механической службы гарантируем оперативный ремонт и решение технических проблем`,
    picture: time
},
  {
    title: 'Кадры',
    content: `Буровой и геологический персонал компании обладает опытом бурения в сложных
горно-геологических условиях и готов успешно решить задачи любой сложности. Специалисты, выпускники геологических факультетов МГУ, МГРИ, РУДН имеют многолетний опыт работ по аналогичным объектам. В штате компании состоят доктора и кандидаты наук по геологическим направлениям.`,
    picture: man
},
  {
    title: 'Работа «под ключ»',
    content: `При необходимости компания готова выполнить работы «под ключ», включая подготовку проекта геологоразведочных работ, полевые работы (бурение, горные работы, геологическое сопровождение, пробоподготовку и т.д.), камеральные работы (написание отчета с подсчетом запасов, разработка и защита ТЭО кондиций и т.п.).`,
    picture: key
  }
];