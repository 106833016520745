import rosneft from '../img/rosneft.png'
import gazprom from '../img/gazprom.png'
import gvgold from '../img/gvgold.png'
import polimetall from '../img/polimetall.png'
import asphalta from '../img/asphalta.png'
import rusburmash from '../img/rusburmash.png'
import urancvetmet from '../img/urancvetmet.png'
import novatek from '../img/novatek.png'
import graystar from '../img/graystar.png'
import kilalitii from '../img/kilalitii.png'
import gpm from '../img/gpm.png'
import vebing from '../img/vebing.png'
import crown from '../img/crown.png'
import vostok from '../img/vostok.png'
import goldball from '../img/goldball.png'

export const ExpaData1 = [
  {
    picture: rosneft
},
{
    picture: gazprom
},
{
    picture: gvgold
},
{
    picture: polimetall
},
{
    picture: asphalta
},]
export const ExpaData2 = [

{
    picture: rusburmash
},
{
    picture: urancvetmet
},
{
    picture: novatek
},
{
    picture: graystar
},
{
    picture: kilalitii
},]
export const ExpaData3 = [
{
    picture: gpm
},
{
    picture: vebing
},
{
    picture: crown
},
{
    picture: vostok
},
{
    picture: goldball
}];